import { PATH_POINTERS } from '@/constants/builder';
import * as serializers from '@/constants/serializer';
import { ENUM } from '@/constants/viewTypes';
import * as comparators from '@/constants/comparators';

import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

const linkedProperties = {
  PROJECT: 'project',
  BUILDING: 'building',
  INVENTORY: 'inventory',
  STATUS: 'status',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const detailsSchema = [
  {
    label: 'label.header',
    prop: 'header',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'unit.unit_name',
    prop: 'name',
    type: schemaItemTypes.STRING,
    rules: [rules.REQUIRED],
  },
  {
    label: 'unit.project_name',
    prop: linkedProperties.PROJECT,
    type: schemaItemTypes.PROJECT,
    rules: [rules.REQUIRED],
    onUpdate: [
      // reset `linkedProperties.BUILDING` property
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.BUILDING,
      },
    ],
  },
  {
    label: 'unit.building_name',
    prop: linkedProperties.BUILDING,
    type: schemaItemTypes.BUILDING,
    rules: [rules.REQUIRED],
    payload: [
      {
        param: 'projectId',
        from: [linkedProperties.PROJECT, 'id'],
      },
    ],
    onUpdate: [
      // set `linkedProperties.PROJECT` property from `project` property of value
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.PROJECT,
        from: 'project',
      },
    ],
  },
  {
    label: 'unit.type',
    prop: 'propertyType',
    type: schemaItemTypes.ENUM,
    rules: [rules.REQUIRED],
    dictionary: dictionaryTypes.PROPERTY_TYPES,
  },
  {
    label: 'unit.rent_amount',
    prop: 'goalRentCost',
    type: schemaItemTypes.NUMBER,
    rules: [rules.REQUIRED, rules.POSITIVE],
  },
  {
    label: 'unit.status',
    type: schemaItemTypes.TITLE,
    titleAppend: {
      type: schemaItemTypes.UNIT_CONTRACTS,
      prop: 'id',
    },
  },
  {
    type: schemaItemTypes.UNIT_ENUM,
    viewType: ENUM.radio,
    dictionary: dictionaryTypes.UNITY_STATUSES,
    prop: linkedProperties.STATUS,
    defaultValue: 'sold',
    payload: [
      {
        param: 'isParentUnit',
        from: ['isParentUnit'],
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'rentalPeriodDate',
    rules: [rules.REQUIRED],
    label: 'client.rent',
    tooltip: {
      text: 'unit.rental_period_tooltip',
      icon: 'mdi-information',
    },
    payload: [
      {
        param: 'multiple',
        from: [linkedProperties.STATUS],
        serializer: serializers.IS_RENTED,
      },
    ],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'unit.tenant',
    prop: 'tenant',
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'salesContractDate',
    label: 'client.sales_contract_date',
    tooltip: {
      text: 'unit.sales_contract_date_tooltip',
      icon: 'mdi-information',
    },
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'unit.owner',
    prop: 'owner',
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['rented'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'salesContractDate',
    label: 'client.sales_contract_date',
    rules: [rules.REQUIRED],
    tooltip: {
      text: 'unit.sales_contract_date_tooltip',
      icon: 'mdi-information',
    },
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['sold'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'unit.owner',
    prop: 'owner',
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.STATUS],
          comparableValues: ['sold'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.STATUS}`,
    },
  },
  {
    label: 'unit.details',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        label: 'unit.number_of_bedrooms',
        prop: 'numberOfBedrooms',
        type: schemaItemTypes.NUMBER,
        rules: [rules.REQUIRED, rules.POSITIVE, rules.INTEGER],
      },
      {
        label: 'unit.number_of_baths',
        prop: 'numberOfBaths',
        type: schemaItemTypes.NUMBER,
        rules: [rules.REQUIRED, rules.POSITIVE, rules.INTEGER],
      },
      {
        label: 'unit.parking_type',
        prop: 'parking',
        type: schemaItemTypes.ENUM,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.PARKING_TYPES,
      },
      {
        label: 'unit.parking_number',
        prop: 'parkingNumber',
        type: schemaItemTypes.STRING,
      },
    ],
  },
  {
    label: 'unit.roof_garden',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        dictionary: dictionaryTypes.APPROVAL,
        prop: 'isRoofGarden',
        defaultValue: false,
      },
    ],
  },
  {
    label: 'unit.swimming_pool',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        dictionary: dictionaryTypes.APPROVAL,
        prop: 'isSwimmingPool',
        defaultValue: false,
      },
    ],
  },
  {
    label: 'unit.internet',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        label: 'unit.internet_login',
        prop: 'internetLogin',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'unit.internet_password',
        prop: 'internetPassword',
        type: schemaItemTypes.STRING,
      },
    ],
  },
  {
    label: 'unit.photos',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.IMAGE_LIST,
        prop: 'photos',
        editable: true,
      },
    ],
  },
  {
    label: 'unit.inventory',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        prop: linkedProperties.INVENTORY,
        type: schemaItemTypes.LIST,
        uniqueKey: 'id',
        // Не дает никакого эффекта. В форме не реализовано еще
        row: true,
        min: 0,
        max: 10,
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                label: 'unit.title',
                prop: 'title',
                type: schemaItemTypes.STRING,
                rules: [rules.REQUIRED],
              },
              {
                label: 'unit.quantity',
                prop: 'quantity',
                type: schemaItemTypes.NUMBER,
                rules: [rules.REQUIRED, rules.POSITIVE],
              },
            ],
          },
          {
            label: 'unit.photos',
            prop: 'images',
            type: schemaItemTypes.IMAGE_LIST,
            rules: [rules.REQUIRED, rules.ALL_LOADED],
            editable: true,
            payload: [
              {
                param: 'listTitle',
                from: [linkedProperties.INVENTORY, PATH_POINTERS.currentIndex, 'title'],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const detailsSchemaEdit = () => {
  const newSchema = [...detailsSchema];
  newSchema.forEach(schema => {
    if (schema.prop === 'rentalPeriodDate' || schema.prop === 'tenant') {
      // eslint-disable-next-line
      delete schema.rules;
    }
  });

  return newSchema;
};

export const addressSchema = [
  {
    label: 'unit.unit_address',
    prop: 'address',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'unit.building',
    prop: 'addressNumber',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'unit.floor',
    prop: 'floor',
    type: schemaItemTypes.NUMBER,
  },
  {
    label: 'unit.apartment_number',
    prop: 'number',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'unit.subway_station',
    prop: 'subwayStation',
    type: schemaItemTypes.STRING,
  },
];

export const areasSchema = [
  {
    label: 'unit.internal_area',
    prop: 'internalArea',
    type: schemaItemTypes.SQUARE,
    rules: [rules.REQUIRED, rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.total_buildable_area',
    prop: 'totalBuildableArea',
    type: schemaItemTypes.SQUARE,
    rules: [rules.REQUIRED, rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.covered_verandas',
    prop: 'coveredVerandas',
    type: schemaItemTypes.SQUARE,
    rules: [rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.uncovered_verandas',
    prop: 'uncoveredVerandas',
    type: schemaItemTypes.SQUARE,
    rules: [rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.lower_level',
    prop: 'lowerLevel',
    type: schemaItemTypes.SQUARE,
    rules: [rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.storage',
    prop: 'storage',
    type: schemaItemTypes.SQUARE,
    rules: [rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
  {
    label: 'unit.plot',
    prop: 'plot',
    type: schemaItemTypes.SQUARE,
    rules: [rules.POSITIVE],
    payload: [
      {
        param: 'areaUnit',
        from: [linkedProperties.PROJECT, 'areaUnit'],
      },
    ],
  },
];
